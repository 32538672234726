.own-card-img:hover{
    -ms-transform: scale(2.1); /* IE 9 */
    -webkit-transform: scale(2.1); /* Safari 3-8 */
    transform: scale(2.1);
    border-radius: 1em;
    z-index:1;
    transition-property: transform ;
    transition: .4s ease-in-out ;
}

.search{
    padding-top: 3em;
    padding-bottom: 1em;
}

.own-card {
  border: 2px;
  border-color: #ba3e06;
}

.own-card-header {
  background-color: #ba3e06;
  color: #fbbd6b;
}

.own-card-body {
  background-color: #fee4c1;
}

.own-card-footer {
  background-color: #fee4c1;
}

@media (min-width: 10em) {
    .card-columns {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
    }
    .card-img-landscape {
      width: 100%;
      height: 60vw;
      object-fit: cover;
    }
    .card-img-portrait {
      height: 100%;
      width: 35vw;
      object-fit: cover;
    }
  }
  
  @media (min-width: 34em) {
    .card-columns {
      -webkit-column-count: 2;
      -moz-column-count: 2;
      column-count: 2;
    }
    .card-img-landscape {
      width: 100%;
      height: 30vw;
      object-fit: cover;
    }
    .card-img-portrait {
      height: 100%;
      width: 25vw;
      object-fit: cover;
    }
  }
  
  @media (min-width: 48em) {
    .card-columns {
      -webkit-column-count: 3;
      -moz-column-count: 3;
      column-count: 3;
    }
    .card-img-landscape {
      width: 100%;
      height: 20vw;
      object-fit: cover;
    }
    .card-img-portrait {
      height: 100%;
      width: 15vw;
      object-fit: cover;
    }
  }
  
  @media (min-width: 62em) {
    .card-columns {
      -webkit-column-count: 4;
      -moz-column-count: 4;
      column-count: 4;
    }
    .card-img-landscape {
      width: 100%;
      height: 15vw;
      object-fit: cover;
    }
    .card-img-portrait {
      height: 100%;
      width: 10vw;
      object-fit: cover;
    }
  }
