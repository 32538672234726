#menu {
    z-index: 2;
    position: fixed;
    width: 100%;
    background-color: #E29A4B;
    background-image: linear-gradient( rgb(251, 189, 107), rgb(253, 241, 197))
}

.ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item:hover{
    border-bottom: 2px solid rgb(116, 42, 8) !important;
}

.subTitle, .ant-menu-horizontal > .ant-menu-item > a {
    color: rgba(0, 0, 0, 0.65);
    font-size: 18px;
    font-weight: bold;
}

.subTitle:hover ,.ant-menu-horizontal > .ant-menu-item > a:hover{
    color: rgb(116, 42, 8) !important;
    font-weight: bold;
}

.subMenu{
    background-color: rgba(253, 241, 197,0.6);
}

.ant-menu-submenu-popup{
    z-index: 1 !important;
}

.ant-menu-submenu-popup > .ant-menu  {
    background-color: rgb(251, 189, 107) !important;
}
